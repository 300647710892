












































import Vue, { PropType } from "vue";
import { get_color_by_name } from "@/utils/colors";
import moment from "moment";
import { DateFilterPayload } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "EnglishSelectedDatePicker",
  data() {
    return {};
  },
  props: {
    selected_dates: {
      type: Object as PropType<DateFilterPayload>
    }
  },
  methods: {
    clear_dates() {
      this.$emit("selected_dates", {
        date_filter: [],
        date_range_value: "",
        date_picker_menu: false
      });
    },
    transform_calender_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("primary"));
      return icon;
    },
    async format_date_range(dates: string[]) {
      if (dates.length === 1)
        this.selected_dates.date_range_value = moment(dates[0]).format(
          "DD MMM YYYY"
        );
      else if (dates.length === 2) {
        this.selected_dates.date_range_value = moment(dates[0]).format(
          "DD MMM YYYY"
        );
        this.selected_dates.date_range_value +=
          "  to  " + moment(dates[1]).format("DD MMM YYYY");
        this.selected_dates.date_picker_menu = false;
        this.$emit("selected_dates", this.selected_dates);
      }
    }
  }
});
